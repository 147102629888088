.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  //   background-color: tomato;
  z-index: 100;

  &-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__contacts {
    display: flex;
    align-items: center;
    gap: 16px;

    img {
      cursor: pointer;
    }

    p {
      cursor: pointer;
      font-weight: 400;
      font-size: 10px;
      line-height: 24px;
      text-transform: uppercase;

      span {
        color: rgba(255, 255, 255, 1);
        border: 0.8px solid #fff;
        border-radius: 4px;
        padding: 2px 4px;
        text-transform: uppercase;
        font-weight: 400;
      }
    }
  }

  &__lang {
    color: #fff;
    cursor: pointer;
    user-select: none;
    position: relative;

    &:hover {
      .lang-list {
        opacity: 1;
        clip: auto;
      }
    }

    .lang-list {
      position: absolute;
      opacity: 0;
      clip: rect(0, 0, 0, 0);
      padding-top: 30px;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.3s;

      ul {
        padding: 5px;
        border-radius: 2px;
        background-color: #fff;
        color: #292e33;

        li {
          padding: 5px 10px;
          color: #292e33;
          border-radius: 2px;
          &:hover {
            background-color: #292e33;
            color: #fff;
          }
        }
      }
    }
  }

  &__contacts-box {
    position: relative;
    z-index: 10;
  }

  &__contacts-box-inner {
    cursor: pointer;
    display: flex;
    gap: 8px;
    color: rgba(255, 255, 255, 1);
    margin: 0 8px;
    border-radius: 2px;
    background-color: #005baa;
    padding: 4px 24px;
  }

  &__contacts-power {
    display: flex;
    gap: 7px;
    align-items: center;
    padding: 2px 7px;
    border: 0.8px solid #fff;
    border-radius: 4px;

    &:hover {
      background-color: #c2c7cc;
    }

    p {
      font-size: 10px;
      line-height: 13px;
      max-width: 160px;
      font-weight: 500;
      text-align: center;
      text-transform: initial;
      color: rgba(255, 255, 255, 1);
    }
  }

  &__list {
    position: absolute;
    top: 40px;
    left: 0;
    width: 20vw;
    background-color: #fff;
    border-radius: 2px;
    padding: 24px 0px;
    color: #292e33;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &::before {
      content: url("../../public/img/menuline.svg");
      position: absolute;
      left: 35px;
      top: 15px;
    }

    &-first {
      p {
        font-size: 16px;
        line-height: 20px;
        text-transform: initial;
        padding: 10px 30px;
        transition: all 0.3s;

        span {
          color: #292e33;
          opacity: 0.5;
          border: none;
        }
      }

      img {
        position: absolute;
        right: 54px;
      }

      padding: 0px 24px;
      position: relative;

      &:hover {
        .header__list-second-outer {
          display: block;
          padding-right: 10px;
        }
        .header__list-first-title {
          background-color: #c2c7cc;
          border-radius: 2px;

          &::before {
            content: url("../../public/img/menuhover.svg");
            position: absolute;
            left: 35px;
            top: 10px;
          }
          &::after {
            content: url("../../public/img/arrowmenuactive.svg");
            position: absolute;
            right: 35px;
            top: 12px;
          }
        }
      }
    }
    &-second-outer {
      display: none;
      position: absolute;
      top: 0;
      right: 20vw;
    }

    &-second-inner {
      min-width: 400px;
      background-color: #fff;
      padding: 16px;
      border-radius: 2px;

      li {
        a {
          display: flex;
          align-items: center;
          gap: 10px;
          opacity: 0.5;
          // transition: all 0.3s;
          border: 1px solid #fff;
          box-sizing: border-box;
          padding: 0 10px;

          &:hover {
            opacity: 1;
            border-color: #c2c7cc;
            border-radius: 2px;
          }
        }

        img {
          position: initial;
          object-fit: cover;
        }

        .sub-title,
        .sub-weight {
          padding: 0;
        }

        .sub-weight {
          padding: 16px 0 16px 16px;
          margin-left: auto;
          border-left: 1px solid #c2c7cc;
          width: 55px;
        }
        .sub-title {
          max-width: 200px;
        }
      }
    }
  }
}

.animated-text {
  max-width: 550px;
  position: relative;
  top: -50px;
  left: 90px;
}

.head-page {
  height: 100vh;

  h1 {
    max-width: 600px;
    font-size: 114px;
    line-height: 114px;
    color: #fff;
    margin-bottom: 0;
    text-align: right;
    font-weight: 800;
    margin-top: 40px;
  }

  h1.dn {
    max-width: 730px;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100vh;
    min-width: 100vw;
    max-height: 1000px;
    object-fit: cover;
    z-index: -1;
  }
}

@media (max-width: 768px) {
  .head-page {
    h1 {
      font-size: 84px;
      line-height: 84px;
      max-width: 500px;
      margin: 0 auto;
      padding-top: 20px;
    }

    .animated-text {
      max-width: 450px;
      left: 15%;
    }

    video {
      object-position: -85vw 0;
      min-height: 120vh;
    }
  }

  .header__contacts-box {
    position: initial;
  }

  .header__list {
    position: absolute;
    top: 80px;
    left: 0;
    width: 90%;
    margin: 0 20px;
  }

  .header__list-first {
    // position: initial;

    &-title {
      &::before {
        left: 34px !important;
        top: 34px !important;
      }
      &::after {
        top: 37px !important;
      }
    }
  }

  .header__list-second-outer {
    top: -5px;
    left: 0;
    top: initial;
    right: initial;
    z-index: 1;
  }

  .header__list-second-inner {
    min-width: 337px;
  }
}

@media (max-width: 550px) {
  .head-page {
    h1 {
      font-size: 54px;
      line-height: 55px;
      padding-top: 10px;
      max-width: 254px;
      margin: initial;
      padding-top: 100px;
    }
    .animated-text {
      max-width: 285px;
      top: -40px;
      left: 0;
    }
    video {
      object-position: -165vw 0px;
      min-height: 125vh;
    }
  }

  .header__contacts {
    // flex-wrap: wrap;

    // // &-box {

    // // }
    &-power {
      display: none;
    }
  }

  .about__content-block {
    flex-wrap: wrap;
    justify-content: center;
  }
}
