@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap');

body {
    margin: 0;
    font-family: "Rubik", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    overflow-x: hidden;
    background: url('../../public/img/bg_2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.container {
    max-width: 1144px;
    padding: 0 20px;
    margin: 0 auto;
}

/* .home {
    padding-bottom: 600px;
} */
/* .App {
    position: relative;
} */

.animation, .animation_layer {
	height: 1000px;
}

.animation {
  display: block;
	position: relative;
	z-index: 10;
}

.animation_layer {
	background-position: bottom center;
	background-size: auto 1038px;
	background-repeat: repeat-x;
	width: 100%;
	position: absolute;
}
.animation_layer.parallax {
	position: fixed;
}

.in-dev {
    position: relative;
    margin-top: -500px;
    z-index: -2;
}

.in-dev img{
    object-fit: cover;
    object-position: center;
    width: 100%;
}

.errorHandler {
    background-color: brown;
    padding: 5px;
    text-align: center;
    color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;
}