.sugar {
    background-image: url("/img/sugarbg.svg"); 
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    color: #FFFFFF;
    padding-top: 250px;
    min-height: 100vh;
    display: flex;
    align-items: center;

    .container {
        padding-right: 5px;
    }

    .product {
        
        .animated-text {
            left: 0;
            position: absolute;
        }

        &__inner {
            display: flex;
            gap: 20px;
            justify-content: space-between;
            align-items: center;
            // padding: 150px 0;

            h1 {
                font-size: 51px !important;
                line-height: 48px !important;
                margin-bottom: 15px !important;
                font-weight: 400;
                font-family: "Rubik Mono One", monospace;
                text-transform: uppercase;
            }
            h4 {
                justify-content: flex-start !important;
                // display: inline !important;
                font-weight: 700 !important;
                font-size: 24px !important;
                margin-bottom: 50px;
                
                span {
                    background-image: none !important;
                    min-width: auto !important;
                }
            }
            img {
                z-index: 0 !important;
                position: relative !important;
                object-fit: contain !important;
                object-position: center !important;
                padding-right: 50px;
                max-height: 590px;
                max-width: 850px;
            }
        }
    
        &__content {
            display: flex;
            flex-direction: column;
            color: #FFF;
            font-size: 14px;
            line-height: 14px;
            font-weight: 400px;
    
            &-inner {
                display: flex;
                flex-wrap: wrap;
                gap: 40px;
                max-width: 630px;
            }
    
            &-descr {
                display: flex;
                gap: 40px;
                p {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                }
            }
            &-nutrit, 
            &-carb,
            &-order {
                display: flex;
                gap: 8px;
                align-items: center;
            }
        }
    
        .breadcrump-inner {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 5px;
            color: #FFF;
            margin-bottom: 40px;
            margin-top: 40px;
        }
        .measur {
            padding: 8px 10px;
            border: 1px solid rgba($color: #fff, $alpha: 0.2);
            border-radius: 2px;
    
            span {
                font-size: 16px;
                font-weight: 700;
                line-height: 16px;
            }
        }
    
        .content {
            p {
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
}

/*================================================
  SugarSlider styles 
=================================================*/

.swiper-wrapper{
    max-height: 550px;
  }
  
  .swiper-pagination {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: -20px;
    min-width: 50px;
  }
  
  .swiper-pagination-bullet {
    background: transparent !important;
    min-width: 70px !important;
    min-height: 70px !important;
    opacity: 0.5 !important;
    img {
        object-fit: contain;
    }
  }

  .swiper-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic, .swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    width: 90px !important;
  }
  
  .swiper-pagination-bullet-active {
    min-width: 80px !important;
    min-height: 80px !important;
    background: transparent !important;
    opacity: 1 !important;
    img {
        object-fit: contain;
    }
  }
  
  .swiper-vertical .swiper-button-next,
  .swiper-vertical .swiper-button-prev {
    transform: rotate(90deg);
    left: calc(100% - 32px);
    width: 24px;
    height: 24px;
      transform-origin: left center;
    &::after {
      display: none;
    }
  }
  .swiper-vertical .swiper-button-prev {
    background-image: url('../img/arrow-circle-next.svg');
      top: calc(var(--swiper-navigation-size) / 2);
  }
  .swiper-vertical .swiper-button-next {
    background-image: url('../img/arrow-circle-prev.svg');
      top: auto;
      bottom: calc(var(--swiper-navigation-size) / 2);
  }
  
@media (max-width: 1400px) { 
    .sugar {
        padding-top: 180px;
    }
}

@media (max-width: 1250px) {
    .product__inner{
        img {
            max-width: 375px;
            object-position: 60px !important;
        }
    }
  }

@media (max-width: 1050px) {
    .swiper-wrapper{
        max-height: 100vh;
    }

    .sugar {
        padding-top: 100px;
        padding-bottom: 200px;
    }

    .swiper-vertical .swiper-button-next {
        bottom: calc(var(--swiper-navigation-size) * 4);
    }

    .product__inner{
        flex-wrap: wrap;
        flex-direction: column-reverse;
        img {
            max-width: 100%;
            object-position: center !important;
        }
        h1 {
            text-align: left !important;
        }
    }
}

@media (max-width: 685px) { 
    .product__inner{
        h1 {
            font-size: 41px !important;
            line-height: 41px !important;
        }
    }
    .swiper-wrapper{
        max-height: 950px;
    }
}

@media (max-width: 550px) {

    // .sugar-layer,
    // .our-layer,
    // .try-layer,
    // .footer-layer {
    //     top: 600px !important;
    // }

    .swiper-pagination {
        transform: translateY(-120%) !important;
    }

    .sugar {
        padding-bottom: 0px;
        margin-top: 50px;
        padding-top: 50px;
        background-position: -124px -22px;

        .product__inner{
            h1 {
                font-size: 25px !important;
                line-height: 25px !important;
            }

            img {
                max-width: 320px;
                max-height: 320px;
            }
        }
    } 
}