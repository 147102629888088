* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover,
a:visited,
a:active {
  color: inherit;
  text-decoration: none; 
}

img {
  max-width: 100%;
}

input,
button,
textarea {
  outline: none;
}

.container {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 160px;
  padding-right: 160px;
  overflow: hidden;

  @media (max-width: 1360px) {
   
    padding-left: 120px;
    padding-right: 120px;
  }

  @media (max-width: 768px) {
    padding-left: 24px;
    padding-right: 24px;
  }
}
