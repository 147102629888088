.about {
    background-image: url("/img/secondBg.svg"); 
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 250px 0 100px 0;
    min-height: 100vh;
    color: #FFFFFF;
    display: flex;
    align-items: center;

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 48px;
        flex-wrap: wrap;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 50px;

        &-block {
            display: flex;
            align-items: center;
            gap: 16px;

            p {
                font-family: "Open Sans", sans-serif;
                font-size: 14px;
                line-height: 24px;
                font-weight: 600;
                max-width: 300px;

                span {
                    font-weight: 800;
                }
            }

            .keln {
                padding-left: 50px;
            }
        }
    }
}

@media (max-width: 1400px) { 
    .about {
        padding-top: 130px;
    }
}

@media (max-width: 1050px) { 
    .container {
        flex-direction: column;
        align-items: center;
    }

    .about {
        padding: 150px 0 250px 0;
    }

    .about__content {
        order: 1;
    }
    .about__img {
        order: 0;
    }
 }



 @media (max-width: 550px) { 
    .about {
        background-position: -80px 0px;
    }
    .about__img {
        max-width: 300px;
    }
 }
