.contactsWrapper {
  color: rgba(232, 216, 216, 0.5);
  // display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding-top: 230px;
  padding-bottom: 104px;

  .footerTitle {
font-size: 84px;
font-weight: 800;
line-height: 70px;
text-align: left;

text-transform: uppercase;
padding-top: 150px;
margin-bottom: 75px;
color: white;

  }

  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
    display: fl;
  }
  @media (max-width: 550px) { 
    padding-top: 61px;
    .footerTitle {
      font-size: 34px;
      line-height: 30px;
      padding-top: 115px;
      margin-bottom: 40px;
    }
  }
}

.contactsWrapper_title {
  display: flex;
  justify-content: space-between;
  padding-right: 32px;
  margin-bottom: 16px;
  width: 25%;

  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {

  }
}



.contacts_title {
  display: block;
  color: var(--5, #FFFFFF);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.contacts_table {
  width: 75%;
}

.contacts_table__icon {
  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.contacts_table__body {
  // display: flex;
  // flex-wrap: wrap;
}

.contacts_icon__item {
  display: flex;

  margin-bottom: 8px;
}

.contacts_icon {
  // margin-left: auto;
  // display: block;
  margin-left: 10px;
}

.contacts_text__decorLocation {
  color: var(--5, #FFFFFF);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.contacts_text__decorWord {
  color: var(--6, #45494d);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.contacts_email__decor {
  color: var(--5, #FFFFFF);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.contacts_email__decorAdres {
  color: var(--7, #676d73);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.contacts_table__bodyItem {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  width: 25%;
  // margin-bottom: 16px;
}

.contacts_table__bodyLink {
  // width: 100%S;
  // display: flex;
  // margin-right: auto;
}

.contacts_phone__decor0 {
  color: var(--7, #676d73);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.contacts_phone__decor1 {
  color: var(--5, #FFFFFF);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.contacts_phone__decor2 {
  color: var(--5, #FFFFFF);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

// =================

.social_list {
  display: flex;

  img {
    margin-right: 28px;
  }
}

.contacts_text {
  color: var(--5, #FFFFFF);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  margin-bottom: 18px;
}
