.footerWrapper {
  // position: absolute;
  // bottom: 0;
  // background: var(--unnamed, #222426);
  

  // border: 1px solid black;

  background-image: url('../../../public/img/footerBackground.svg');
  min-height: 100vh;
  // background-size: contain;

  background-size: cover; /* або background-size: contain; */
  padding-bottom:  74px;


  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
  }

  @media (max-width: 550px) {
    margin-top: -160px;
    background-position: -175px 0px;
  }
}
