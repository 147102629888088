.our,
.try {
    min-height: 100vh;
    position: relative;

    h1 {
        padding-top: 80px;
        font-size: 101px;
        line-height: 78px;
        color: #fff;
        margin-bottom: 0;
        text-align: center;
        font-weight: 700;
        position: relative;
        z-index: 1;
    }

    video {
        position: absolute;
        top: 0;
        left: 0;
        max-height: 86vh;
        height: 100%;
        // min-height: 100vh;
        min-width: 100vw;
        // max-height: 1000px;
        object-fit: cover;
        object-position: 0 -55vh;
        z-index: 0;
    }

}

.our {
    top: 0;
    background: url('../../public/img/bg_t.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    .animated-text {
        max-width: 410px;
        left: initial;
        transform: translateX(82%);
        top: -200px;
        z-index: 1;
    }
    video { 
        max-height: 100vh;
        object-position: 0 -55vh;
    }

    h1 {
        padding-top: 60px;
    }

}

.our.en .animated-text,
.our.dn .animated-text { 
    top: -140px;
    max-width: 370px;
    transform: translateX(95%) skewY(12deg);
}

.try {
    top: -120px;
    background: url('../../public/img/bg_2.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    h1 {
        max-width: 310px;
        margin: 0 auto;
        font-size: 54px;
        line-height: 54px;
    }

    .animated-text {
        left: initial;
        max-width: 550px;
        transform: translateX(49%);
        top: -240px;
        z-index: 1;
    }
}

// .try.en, .try.dn  .animated-text {

// }
.title-inner {
    padding-top: 50px;
}

.home-mobile {
    .our, .try {
        min-height: initial;
        // top: -15vh;
    }
}

@media (max-width: 1400px) { 
    .title-inner {
        padding-top: 20px;
    }
    .our video, .try video {
        object-position: 0 -40vh;
        min-height: 100vh;
    }
}


@media (max-width: 1050px) { 
    .try {
        top: 100px;
        .animated-text {
            transform: translateX(-40%);
            left: 40%;
            top: -355px;
        }
    }

    .our {
        top: 20vh;
        .animated-text {
            transform: translateX(-50%);
            left: 50%;
        }
    }
}

@media (max-width: 768px) { 
    .try, .our {

        video {
            // object-fit: contain;
            // object-position: 0 15vh;
            min-height: 75vh;
        }

        h1 {
            font-size: 54px;
            line-height: 54px;
            max-width: 250px;
        }
    }

    .our {
        h1 {
            margin: 0 auto;
            padding-top: 30vh;
        }
        .animated-text { 
            max-width: 350px;
        }
    }

    .try {
        padding-top: 150px;

        .animated-text { 
            top: -300px;
        }
    }

    .sugar {
        padding-bottom: 300px;
    }
}

@media (max-width: 550px) {  
    .try, .our {
        background: initial;
        h1 { 
            font-size: 24px;
            line-height: 24px; 
        } 

        video {
            min-height: 100vh;
            z-index: -1;
            object-position: -25vh -20vh;
        }
    }

    .try {
        top: -22vh;
        padding-top: 115px;
        padding-bottom: 65px;

        h1 { 
            padding-top: 40px;
            max-width: 185px;
        }
        
        .animated-text {
            top: -130px;
            max-width: 305px;
        }
    }

    .our {
        top: -12vh;
        padding-bottom: 25px; 

        h1 {
            padding-top: 16vh;
            font-size: 61px;
        }
        .animated-text {
            max-width: 250px;
            top: -110px;
        }
    }

    .try.en .animated-text, .try.dn .animated-text {
        top: -90px;
    }
}