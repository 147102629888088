.contactsWrapper_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 25%;
  margin-bottom: 16px;
  padding-right: 32px;
  

  h2 {
    display: block;
    color: var(--5, #FFFFFF);
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }


  
  @media (max-width: 1360px) {
   

  }

  @media (max-width: 768px) {
    margin-bottom: 32px;
width: 100%;
padding-right: 0px;

  }
}
